<template>
  <v-row>
    <v-col cols="6" xl="6">
      <v-card>
        <div
          v-for="(product, i) in post.products"
          :key="i"
        >
          <v-card-text>
            <edit-product
              :templateTitle.sync="product.templateTitle"
              :title.sync="product.title"
              :subtitle.sync="product.subtitle"
              :description.sync="product.description"
              :chars.sync="product.chars"
              :promoCode.sync="product.promoCode"
              :instruction.sync="product.instruction"
              :delivery.sync="product.delivery"
              :price.sync="product.price"
              :fullPrice.sync="product.fullPrice"
              :otherPrice.sync="product.otherPrice"
              :image.sync="product.image"
              :productId.sync="product.id"
              :link.sync="product.link"
              :aliLink.sync = "product.aliLink"
              :shop.sync="product.shop"
              :finalText="product.finalText"
              :postId.sync="product.postId"
              :rating.sync="product.rating"
              :channel.sync="product.channel"
              :partnerId.sync="product.partnerId"
              :warranty.sync ="product.warranty"
              :deadline.sync="product.deadline"
              :errors.sync="product.errors"
              :links.sync="product.links"
              :prices.sync="product.prices"
              :erid.sync = "product.erid"
              :aepCreativeId.sync = "product.aepCreativeId"
              @updateFinalText="updateFinalText"
              @productLoaded="productLoaded = true"
            ></edit-product>
          </v-card-text>
        </div>
      </v-card>
<!--				<v-btn color="green" @click="addProduct()">Добавить товар</v-btn>-->
    </v-col>
    <v-col cols="6" lg="6">
      <v-row>
        <v-col cols="12">
            <div
                v-for="(product, i) in post.products"
                :key="i"
            >
              <result-post ref="resultPost" :post="post" :finalText.sync="product.finalText" :postMethod.sync="product.postMethod" :productLoaded="productLoaded"></result-post>
            </div>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title>Время публикации</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6"><publisher :product="post.products[0]" :finalLink.sync="post.products[0].link" :disableAlert.sync="post.products[0].disableAlert" :pid.sync="post.products[0].pid"></publisher></v-col>
                <v-col cols="6"><schedule :product="post.products[0]" :finalLink.sync="post.products[0].link" :postId="post.products[0].postId"></schedule></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CreatePost',
  components: {
    EditProduct: () => import('../../components/posts/EditProduct'),
    ResultPost: () => import('../../components/posts/ResultPost'),
    Schedule: () => import('../../components/posts/Schedule'),
    Publisher: () => import('../../components/posts/Publisher')
  },
  data(){
    return{
      post: {
        products: [],
      },
      defaultProduct: {
        id: 0, templateTitle: '', title: '', subtitle: '', description: '', chars: '', promoCode: '', instruction: '',
        price: '', otherPrice: '', fullPrice: '', image: '', link:'', shop: {}, finalText: '', delivery: '',
        aliLink: '', postId: 0, rating: '', channel: [1, 2, 3, 9], partnerId: 2, warranty: '', deadline: '', disableAlert: true,
        erid: '', aepCreativeId: '',
        links: [], pid: 0, postMethod: 'sendPhoto', prices: {lowest: true}, button_text: '💥 Перейти к товару 💥',
        errors: [{err: 'img', text: 'Не загружена картинка'}, {err: 'notSaved', text: 'Продукт не сохранён в CRM'},
          // {err: 'noLinks', text: 'Не заданы ссылки (нужна хотя бы одна)'}
        ],
      },
      testArray: [],
      finalLink: '', // чтобы пробросить итоговый URL из компонента Schedule в компонент ResultPost (и обновить итоговую ссылку)
      productLoaded: false,
    }
  },
  created(){

    if (this.post.products.length < 1){
      this.post.products.push(this.defaultProduct)
    }
    if (this.$store.state.channels.length < 1){
      this.$store.dispatch('getChannels')
    }
    if (this.$store.state.partners.length < 1){
      this.$store.dispatch('getPartners')
    }
    console.log('channels:', this.post.products[0].channel)
  },
  methods:{
    addProduct(){
      this.post.products.push(this.defaultProduct)
    },
    updateFinalText(text){
      this.$refs.resultPost[0].setText(text, true, true)
      this.post.products[0].finalText = text
    },
    sendPost(){

    }
  }

}
</script>

<style scoped>

</style>
